import React from 'react'
import { graphql } from 'gatsby'

import 'twin.macro'

import SEO from '../../components/seo'
import ArticleContainer from '../../components/article-container'
import ArticleContainerHeading from '../../components/article-container-heading'
import RichArticle from '../../components/rich-article'

const ArtefactEntry = ({ data }) => {
  const { entry } = data

  return (
    <>
      <SEO data={entry.seomatic} />
      <ArticleContainer
        heading={entry.title}
        ctaText={entry.ctaText}
        ctaUrl={entry.ctaUrl}
        ctaText={entry.ctaText}
        ctaUrl={entry.ctaUrl}
      >
        <ArticleContainerHeading
          heading={entry.title.toLowerCase()}
          subheading={
            entry.youngProducer[0].firstName +
            (entry.youngProducer[0].region
              ? `, ${entry.youngProducer[0].region}`
              : '')
          }
          avatarImage={
            entry.youngProducer[0].thumbnailImage.length
              ? entry.youngProducer[0].thumbnailImage[0]
              : null
          }
        ></ArticleContainerHeading>
        <RichArticle
          heroVideo={entry.heroVideo.length ? entry.heroVideo[0] : null}
          heroImage={entry.heroImage.length ? entry.heroImage[0] : null}
          intro={entry.intro}
          blocks={entry.richArticle}
          isInsideArticleContainer={true}
        />
      </ArticleContainer>
    </>
  )
}

export default ArtefactEntry

export const query = graphql`
  query($id: ID) {
    entry: craftEntryInterface(remoteId: { eq: $id }) {
      seomatic {
        ...SEO
      }
      title
      ... on Craft_artefact_artefactImage_Entry {
        heroImage {
          ...ImageFullWidth
        }
        heroVideo {
          url
        }
        intro
        richArticle {
          ...RichArticleTextBlock
          ...RichArticleQuoteBlock
          ...RichArticleImageFullWidthBlock
          ...RichArticleImageRowBlock
          ...RichArticleImageCarouselBlock
          ...RichArticleVideoBlock
        }
        youngProducer {
          ...YoungProducer
        }
        ctaText
        ctaUrl
      }
      ... on Craft_artefact_artefactVideo_Entry {
        heroImage {
          ...ImageFullWidth
        }
        heroVideo {
          url
        }
        intro
        richArticle {
          ...RichArticleTextBlock
          ...RichArticleQuoteBlock
          ...RichArticleImageFullWidthBlock
          ...RichArticleImageRowBlock
          ...RichArticleImageCarouselBlock
          ...RichArticleVideoBlock
        }
        youngProducer {
          ...YoungProducer
        }
        ctaText
        ctaUrl
      }
      ... on Craft_artefact_artefactObject_Entry {
        heroImage {
          ...ImageFullWidth
        }
        heroVideo {
          url
        }
        intro
        richArticle {
          ...RichArticleTextBlock
          ...RichArticleQuoteBlock
          ...RichArticleImageFullWidthBlock
          ...RichArticleImageRowBlock
          ...RichArticleImageCarouselBlock
          ...RichArticleVideoBlock
        }
        youngProducer {
          ...YoungProducer
        }
        ctaText
        ctaUrl
      }
    }
  }
`
